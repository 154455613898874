import { FC, useEffect, useState } from 'react'
import axios from 'axios'

import { Wrapper } from 'ui/layout/PageLayout'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import {
  HeaderBlock,
  StartSection,
  AdvantagesSection,
  StagesSection,
  PartnersSection,
  CategoriesSection,
  RequestSection,
  FooterBlock,
} from '../../components'
import { toast } from '../../../../ui'

const baseUrl =
  process.env.REACT_APP_STAND === 'prod'
    ? 'https://api.airba.kz'
    : 'https://api.mp-test.airba.dev'

const partnersUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8080'
    : process.env.REACT_APP_STAND === 'prod'
    ? 'https://cabinet.airba.kz'
    : 'https://cabinet.test.airba.dev'

const authUrl =
  process.env.REACT_APP_STAND === 'prod'
    ? 'https://api.airbapay.kz/auth/api/v1/authenticate'
    : 'https://sapi.airbapay.kz/auth/api/v1/authenticate'

const orgInfoUrl =
  process.env.REACT_APP_STAND === 'prod'
    ? 'https://finapi.technodom.kz/profiler/api/v1/enpf/org-info'
    : 'https://sfinapi.technodom.kz/profiler/api/v1/enpf/org-info'

const credentials =
  process.env.REACT_APP_STAND === 'prod'
    ? {
        userId: 'ac3b4656-7943-4836-85a6-97bfa99f1a0e',
        userSecret: 'NeBFxyGa5lPkiPXVZK8m09qulzOVR3yE',
      }
    : {
        userId: 'efcd3a67-6362-48aa-a7fa-4b4fcd4dc207',
        userSecret: 'HBe9gh2GUO9un5c9F6lkJ8K8xH7Nj77f',
      }

axios.defaults.baseURL = baseUrl

const Main: FC = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [bin, setBin] = useState('')
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')

  const [isAgree, setIsAgree] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [mobileMenu, setMobileMenu] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isReadonlyCompany, setIsReadonlyCompany] = useState(true)

  function matchNumberMask(value: string) {
    return value.replace(/[^0-9.]/g, '')
  }

  const handlePhone = () => {
    if (matchNumberMask(phone).length < 11) {
      setPhoneError(true)
    } else {
      setPhoneError(false)
    }
  }

  const onSubmit = async e => {
    e.preventDefault()

    setIsLoading(true)

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha('submit')

    if (token) {
      axios
        .post(`/merchant-profile/api/v1/applications/`, {
          name: name,
          phone: matchNumberMask(phone),
          bin,
          email,
          company_name: company,
          g_recaptcha_response: token,
        })
        .then(res => {
          setIsLoading(false)

          window.location.replace(
            `${partnersUrl}/auth/sign-up?phone=${matchNumberMask(
              phone,
            )}&application_id=${res.data.application_id}`,
          )
        })
        .catch(err => {
          if (err.response.data.error.message === 'already exist') {
            toast('Компания уже существует')
          } else {
            toast('Проверьте правильность введенных данных')
          }

          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    setIsReadonlyCompany(true)
    if (bin.length === 12) {
      axios.post(authUrl, credentials).then(res => {
        axios
          .get(`${orgInfoUrl}/${bin}`, {
            headers: {
              authorization: res.data.tokenType + ' ' + res.data.accessToken,
            },
          })
          .then(res => {
            setCompany(res.data.orgName)
          })
          .catch(() => {
            setCompany('')
            setIsReadonlyCompany(false)
          })
      })
    }
  }, [bin])

  return (
    <Wrapper>
      <HeaderBlock
        mobileMenu={mobileMenu}
        setMobileMenu={setMobileMenu}
        successPage={false}
      />
      <main>
        <StartSection />
        <AdvantagesSection />
        <StagesSection />
        <PartnersSection />
        <CategoriesSection />
        <RequestSection
          name={name}
          isReadonlyCompany={isReadonlyCompany}
          setName={setName}
          phoneError={phoneError}
          phone={matchNumberMask(phone)}
          setPhone={setPhone}
          isAgree={isAgree}
          setIsAgree={setIsAgree}
          isLoading={isLoading}
          onSubmit={onSubmit}
          handlePhone={handlePhone}
          bin={bin}
          email={email}
          company={company}
          setBin={setBin}
          setEmail={setEmail}
          setCompany={setCompany}
        />
      </main>
      <FooterBlock />
    </Wrapper>
  )
}

export default Main
